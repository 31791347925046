@import url(//fonts.googleapis.com/css2?family=Open+Sans:wght@400;600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%;
}

p code {
  font-family: 'Courier New', monospace;
  font-weight: bold;
  display: inline-block;
  background: rgb(219, 219, 219);
  padding: 0px 4px;
  border-radius: 3px;
}

@font-face {
    font-family: Gotham-Rounded;
    src: url(/static/media/Gotham-Rounded-Light.cbdf452a.ttf);
}

@font-face {
    font-family: Gotham-Rounded-Book;
    src: url(/static/media/Gotham-Rounded-Book.108ace2d.ttf);
}

@font-face {
    font-family: Gotham-Rounded-Medium;
    src: url(/static/media/Gotham-Rounded-Medium.a823f475.ttf);
}

#canvases {
    flex: 1 1 auto;
    position: relative;
    z-index: 0;
    overflow: auto;
    padding-top: 100px;
}



.tabs {
    position: absolute;
    display: flex;
    z-index: 1;
    align-self: center;
    transition: left 0.5s, right 0.5s;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 4px;

    height: calc(100% - 120px);
    left: 0;
    margin-top: 10px;
    top: 97px;
}

.tabs--hidden {
    display: flex;
    align-items: flex-start;
    height: 95%;
    left: -490px;
}

.tabs__items {
    overflow: hidden;
    pointer-events: auto;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 0;
}

.tabs__content {
    border-radius: 4px;
    width: 300px;
    height: 100%;
    background: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    pointer-events: auto;
}

.content-panel {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100%;
}

.content-panel.content-panel--hidden {
    display: none;
}

.content-panel__title {
    cursor: default;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 10px 20px;
    height: 34px;
    background-color: #e5f6fd;
    color: #697582;
    font-size: 14px;
}
.content-panel--shapes {
  position: relative;
}
.content-panel--shapes-container {
  overflow-y: hidden;
  height: 100%;
}
.content-panel--shapes .smart-shapes {
    height: 95%;
    padding: 5px;
}
.placeholder-label {
    margin-bottom: 0;
}

.content-panel__add-button {
    flex: none;
    z-index: 1;
    cursor: default;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin: 10px 0 10px;
}

.tabs-hide-button {
    position: absolute;
    z-index: 2;
    outline: none;
    border: none;
    padding: 15px;
    background-color: transparent;
    cursor: pointer;
    bottom: 0;
    left: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.tabs-hide-button svg {
    width: 25px;
    height: 25px;
}

.tabs-hide-button--active {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}

.tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 30px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    transition: fill 300ms, color 300ms;

}

.tab-item use {
    fill: #697582;
    color: #697582;
    transition: fill 300ms, color 300ms, stroke 300ms;
}
.tab-item:hover,
.tab-item.tab-item--active
.tab-item__icon-wrapper {
        background: #fff;
    }

.tab-item:hover,
.tab-item.tab-item--active
.tab-item__title {
        color: #00b5e2;
    }

.tab-item:hover,
.tab-item.tab-item--active
use {
        fill: #00b5e2;
        color: #00b5e2;
    }

.dcl-widget-edit-text {
    margin-bottom: 10px;
    overflow: auto;
}

.tab-item__title {
    margin-top: 5px;
    color: #697582;
    font-size: 12px;
    transition: color 300ms;
}

.tab-item__icon {
    width: 48px;
    height: 48px;
}

.dcl-widget-upload-list,
.dcl-widget-edit-text,
.dcl-widget-add-shape {
    flex: 1 1;
    z-index: 0;
}

.dcl-widget-upload-list {
    margin-bottom: 10px;
    overflow: auto;
}

.dcl-widget-add-shape {
    margin-top: 20px;
}

.dcl-widget-add-text,
.dcl-widget-upload-button,
.dcl-widget-add-placeholder-image {
    padding: 0 30px;
}

.tab-item__info-icon {
    margin-top: 1px;

}

.tab-item__info-icon  svg {
    padding: 2px;
}
.tab-item__info-icon:hover {
    fill: #00b5e2;
}

.tab-item__info-icon-wrapper {
    background-color: #e1e6ea;
    border: 3px solid #697582;
}

.tab-item__info-icon-wrapper--active,
.tab-item__info-icon-wrapper:hover {
    background-color: #fff;
    border: 3px solid #00b5e2;
}

.tab-item-information {
    padding-left: 1px;
}

.contextual-toolbar {
    position: absolute;
    width: 100%;
    z-index: 2;
}

.contextual-toolbar .toolbar {
    position: relative;
}

.contextual-toolbar .toolbar .dcl-contextual-toolbar {
    background-color: white;
    width: 100%;
    top: 0;
    transition: top 0.4s;
    border-bottom: solid 1px #ccc;
}
.contextual-toolbar .toolbar .dcl-contextual-toolbar-primary-row {
    display: flex;
    justify-content: center;
    width: 100%;
}
.contextual-toolbar .toolbar .dcl-contextual-toolbar.dcl-invisible {
    top: -55px;
    visibility: visible;
}
.contextual-toolbar .toolbar .dcl-toolbar-button:not(.active),
.contextual-toolbar .toolbar .dcl-expanding-button:not(.active) {
    background-color: white;
}
.contextual-toolbar .itemtoolbar {
    position: relative;
    background-color: white;
    top: 56px;
}

.designer {
    display: flex;
    flex: 1 1;
    position: relative;
    z-index: 0;
    margin-top: 1px;
    overflow: hidden;
    background-color: #f0f0f0;
    opacity: 1;
    transition: opacity 2s;
    height: 92%;
}


.footer__section {
    display: flex;
    flex: 1 1;
    justify-content: center;
}


.dcl-widget-canvas-history,
.dcl-widget-canvas-zoom,
.dcl-widget-preview-document {
    display: inline-block;
    height: 100%;
}

.dcl-widget-save-document {
    display: inline-block;
    margin-right: 25px;
}

.dcl-widget-preview-document {
    align-self: center;
    margin: 0 15px;
}

.dcl-action-btn--undo,
.dcl-action-btn--redo {
    padding: 10px 20px;
}

.dcl-action-btn-group .dcl-action-btn {
    margin: 0 15px;
}

.footer__section .dcl-action-btn,
.dcl-zoom,
.dcl-action-btn-group,
.dcl-canvas-history,
.footer .dcl-button--toolbar {
    height: 100%;
}

.footer__section .dcl-action-btn-group {
    display: inline-flex;
}

.pagination__container {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}


table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #0088a9;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0088a9;
    color: white;
    font-size: 13px;
    font-weight: normal;
}

table td,
table th {
    padding: 8px;
    width: 15px;
}

table tbody {
    display: block;
    max-height: 400px;
    min-height: 400px;
    overflow-y: scroll;
}

table thead,
table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 49px;
}

table tr:hover {
    background-color: rgb(225, 230, 235);
}

.uploads-selected {
    background-color: rgb(225, 230, 235);
}

.all-uploads-root {
    display: flex;
    flex-wrap: wrap;
}

.all-uploads-div {
    flex: 4 1;
    position: relative;
}

.all-uploads-rendering-view {
    flex: 2 1;
    padding-left: 5px;
}

.upload-image {
    height: inherit;
    width: inherit;
    cursor: pointer;
}

.upload-tags-btn {
    color: rgb(0 92 117);
    cursor: pointer;
    margin: 0 3px;
}
.upload-tags-edit-root .upload-tag-btn-div .add-tag-btn {
  padding: 2px 13px;
}
.upload-tags-edit-root .upload-tag-btn-div .cancel-btn {
  padding: 2px 4px;
}
.upload-size-is-error {
    color: rgb(188 33 17) !important;
    background-color: rgb(240 85 58) !important;
}
.upload-size-is-not-error {
    color: rgb(13 17 22) !important;
}
.upload-unsupported-file {
    color: rgb(188 33 17);
}

.upload-tags-div {
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1px;
    gap: 1px;
}

.upload-tags-edit-root {
    display: flex;
    flex-wrap: wrap;
}

.add-tags {
    padding-left: 4px;
}

.upload-tag-btn-div {
    display: flex;
}

.upload-tags-edit-input {
    width: 90px;
}

.render-format-div {
    width: 250px;
    height: 250px;
    margin: auto;
}
.render-view-image {
    height: inherit;
    width: inherit;
}

.upload-tags-column {
    width: 45px;
}

.tabs-no-results-div {
    text-align: center;
}

.tabs-no-results {
    height: 262px;
    width: 300px;
}

.upload-fetching-tags {
    color: rgb(0 92 117);
}
.upload-fetching-tags:after {
    content: ' .';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        font-size: 30px;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: rgb(0 92 117);
        font-size: 30px;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: 0.25em 0 0 rgb(0 92 117), 0.5em 0 0 rgba(0, 0, 0, 0);
        font-size: 30px;
    }
    80%,
    100% {
        text-shadow: 0.25em 0 0 rgb(0 92 117), 0.5em 0 0 rgb(0 92 117);
        font-size: 30px;
    }
}

@keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        font-size: 30px;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: rgb(0 92 117);
        font-size: 30px;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: 0.25em 0 0 rgb(0 92 117), 0.5em 0 0 rgba(0, 0, 0, 0);
        font-size: 30px;
    }
    80%,
    100% {
        text-shadow: 0.25em 0 0 rgb(0 92 117), 0.5em 0 0 rgb(0 92 117);
        font-size: 30px;
    }
}
.upload-tags-div .tag button.close {
    font-size: 11px;
}

.upload-edit-div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 3px;
    gap: 3px;
}

.upload-edit-div button {
    width: 90px;
}

.upload-image-div {
    height: 50px;
    width: 50px;
}

.render-format-spinner-div{
    text-align: center;
    padding: 20%;
}

.upload-approve-div {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    flex-wrap: wrap;
}
.upload-approve-div button {
    width: 90px;
}
.upload-collection-actions-column{
    width: 25px;
}

.upload-list-button-div{
    position: absolute;
    right: 0px;
    top: -34px;
}

.upload-list-button-div button{
    width:179px;
}

.approve-select-column-div{
    text-align: center;
}

.approve-select-column-div div {
   margin: 0px !important;
}

.approve-select-column-div div input {
   position: static !important;
}

.search-icons .input-group{
    width: 20% !important;
}

.search-icons .form-group {
  margin-bottom: 5px;
}

.search-icons .form-group .input-group .form-control {
  padding: 5px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

.upload-btn-text-div{
    color : rgb(0 111 138);
}

.file-upload {
    height: 100%;
}
.file-upload .hidden-file-input {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    position: absolute;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
}
.file-upload .hidden-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.file-upload__selected-file {
    padding: 10px;
    border-radius: 6px;
    background-color: #354259;
    color: #fff;
    width: 100%;
}
.file-upload__selected-file .file-upload__file-name {
    font-size: 12px;
    font-weight: bold;
}
.file-upload__selected-file .file-upload__file-size {
    font-size: 10px;
}
.file-upload__selected-file button.close {
    color: #fff;
    font-size: 20px;
}

.file-dropzone {
    border: dashed 2px #c4cdd6;
    height:  100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    background: #edf3f8;
}
.file-dropzone .file-drag-over {
    border: dashed 2px #354259;

        pointer-events: none;

}

.file-dropzone-wrapper {
    width: 100%;
    height: 100%;
}

small.has-error.help-block {
    color: #d24a35;
}


.admin-container {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
    margin:10px 10px 0 10px;
}

.collection-container{
    display: flex;
    height: 85px;
}

.upload-container{
    height: 180px;
}

.tabs-container{
    height: calc(100%-265px);
}

.collection-container-box{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.application-name{
    font-size: 20px;
    color: rgb(13 17 22);
}
.collection-container-select-root{
    display: flex;
}
.collection-container-select-root-flex{
    flex: 1 1;
    display: flex;
}
.collection-container-select-root-div{
    flex:1 1;
    margin-top: 15px;
}
.collection-container-select-name{
    color:rgb(0 111 138)
}
.collection-container-select-div{
    flex:2 1;
}
.collection-container-btn-root{
    flex: 2 1;
    position:relative;
}
.collection-container-btn-div{
    position:absolute;
    right: 0;
    display: flex;
}

.collection-container-btn-div div ul {
    max-height: 229px;
    overflow-y: auto;
    width: 100%;
}
.collection-container-btn-dropdown {
    margin-left: 10px;
    height: 100%;
    width: 196px;
}
.collection-container-btn-dropdown div {
    height: 100%;
}
.collection-container-btn-dropdown div button {
    height: 100%;
}

.dropdown-option {
    display: flex;
    justify-content: space-between;
    padding-left: 14px;
    padding-top: 4px;
    font-size: 15px;
    padding-right: 14px;
}

.horizontalLine {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #edf3f8;
    width: 100%;
}
.admin-collection-div{
    display: flex;
    flex-wrap: wrap;
    padding-top: 9px;
    grid-gap: 2px;
    gap: 2px;
}

.admin-collection-input-div{
    padding-right: 7px;
}

.admin-collection-save-cancel-div{
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    height: 28px;
}

.tabs-spinner{
    text-align: center;
}

