.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

.upload-btn-text-div{
    color : rgb(0 111 138);
}
