
.admin-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin:10px 10px 0 10px;
}

.collection-container{
    display: flex;
    height: 85px;
}

.upload-container{
    height: 180px;
}

.tabs-container{
    height: calc(100%-265px);
}

.collection-container-box{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.application-name{
    font-size: 20px;
    color: rgb(13 17 22);
}
.collection-container-select-root{
    display: flex;
}
.collection-container-select-root-flex{
    flex: 1;
    display: flex;
}
.collection-container-select-root-div{
    flex:1;
    margin-top: 15px;
}
.collection-container-select-name{
    color:rgb(0 111 138)
}
.collection-container-select-div{
    flex:2;
}
.collection-container-btn-root{
    flex: 2;
    position:relative;
}
.collection-container-btn-div{
    position:absolute;
    right: 0;
    display: flex;
}

.collection-container-btn-div div ul {
    max-height: 229px;
    overflow-y: auto;
    width: 100%;
}
.collection-container-btn-dropdown {
    margin-left: 10px;
    height: 100%;
    width: 196px;
}
.collection-container-btn-dropdown div {
    height: 100%;
}
.collection-container-btn-dropdown div button {
    height: 100%;
}

.dropdown-option {
    display: flex;
    justify-content: space-between;
    padding-left: 14px;
    padding-top: 4px;
    font-size: 15px;
    padding-right: 14px;
}

.horizontalLine {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #edf3f8;
    width: 100%;
}
.admin-collection-div{
    display: flex;
    flex-wrap: wrap;
    padding-top: 9px;
    gap: 2px;
}

.admin-collection-input-div{
    padding-right: 7px;
}

.admin-collection-save-cancel-div{
    display: flex;
    gap: 2px;
    height: 28px;
}

.tabs-spinner{
    text-align: center;
}
