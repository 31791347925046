.search-icons .input-group{
    width: 20% !important;
}

.search-icons .form-group {
  margin-bottom: 5px;
}

.search-icons .form-group .input-group .form-control {
  padding: 5px;
}
