body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%;
}

p code {
  font-family: 'Courier New', monospace;
  font-weight: bold;
  display: inline-block;
  background: rgb(219, 219, 219);
  padding: 0px 4px;
  border-radius: 3px;
}
