.contextual-toolbar {
    position: absolute;
    width: 100%;
    z-index: 2;
}

.contextual-toolbar .toolbar {
    position: relative;
}

.contextual-toolbar .toolbar .dcl-contextual-toolbar {
    background-color: white;
    width: 100%;
    top: 0;
    transition: top 0.4s;
    border-bottom: solid 1px #ccc;
}
.contextual-toolbar .toolbar .dcl-contextual-toolbar-primary-row {
    display: flex;
    justify-content: center;
    width: 100%;
}
.contextual-toolbar .toolbar .dcl-contextual-toolbar.dcl-invisible {
    top: -55px;
    visibility: visible;
}
.contextual-toolbar .toolbar .dcl-toolbar-button:not(.active),
.contextual-toolbar .toolbar .dcl-expanding-button:not(.active) {
    background-color: white;
}
.contextual-toolbar .itemtoolbar {
    position: relative;
    background-color: white;
    top: 56px;
}
