.designer {
    display: flex;
    flex: 1 1;
    position: relative;
    z-index: 0;
    margin-top: 1px;
    overflow: hidden;
    background-color: #f0f0f0;
    opacity: 1;
    transition: opacity 2s;
    height: 92%;
}


.footer__section {
    display: flex;
    flex: 1 1;
    justify-content: center;
}


.dcl-widget-canvas-history,
.dcl-widget-canvas-zoom,
.dcl-widget-preview-document {
    display: inline-block;
    height: 100%;
}

.dcl-widget-save-document {
    display: inline-block;
    margin-right: 25px;
}

.dcl-widget-preview-document {
    align-self: center;
    margin: 0 15px;
}

.dcl-action-btn--undo,
.dcl-action-btn--redo {
    padding: 10px 20px;
}

.dcl-action-btn-group .dcl-action-btn {
    margin: 0 15px;
}

.footer__section .dcl-action-btn,
.dcl-zoom,
.dcl-action-btn-group,
.dcl-canvas-history,
.footer .dcl-button--toolbar {
    height: 100%;
}

.footer__section .dcl-action-btn-group {
    display: inline-flex;
}
