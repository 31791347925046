.file-dropzone {
    border: dashed 2px #c4cdd6;
    height:  100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    background: #edf3f8;
}
.file-dropzone .file-drag-over {
    border: dashed 2px #354259;

        pointer-events: none;

}

.file-dropzone-wrapper {
    width: 100%;
    height: 100%;
}

small.has-error.help-block {
    color: #d24a35;
}
