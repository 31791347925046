
table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #0088a9;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0088a9;
    color: white;
    font-size: 13px;
    font-weight: normal;
}

table td,
table th {
    padding: 8px;
    width: 15px;
}

table tbody {
    display: block;
    max-height: 400px;
    min-height: 400px;
    overflow-y: scroll;
}

table thead,
table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 49px;
}

table tr:hover {
    background-color: rgb(225, 230, 235);
}

.uploads-selected {
    background-color: rgb(225, 230, 235);
}

.all-uploads-root {
    display: flex;
    flex-wrap: wrap;
}

.all-uploads-div {
    flex: 4;
    position: relative;
}

.all-uploads-rendering-view {
    flex: 2;
    padding-left: 5px;
}

.upload-image {
    height: inherit;
    width: inherit;
    cursor: pointer;
}

.upload-tags-btn {
    color: rgb(0 92 117);
    cursor: pointer;
    margin: 0 3px;
}
.upload-tags-edit-root .upload-tag-btn-div .add-tag-btn {
  padding: 2px 13px;
}
.upload-tags-edit-root .upload-tag-btn-div .cancel-btn {
  padding: 2px 4px;
}
.upload-size-is-error {
    color: rgb(188 33 17) !important;
    background-color: rgb(240 85 58) !important;
}
.upload-size-is-not-error {
    color: rgb(13 17 22) !important;
}
.upload-unsupported-file {
    color: rgb(188 33 17);
}

.upload-tags-div {
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
}

.upload-tags-edit-root {
    display: flex;
    flex-wrap: wrap;
}

.add-tags {
    padding-left: 4px;
}

.upload-tag-btn-div {
    display: flex;
}

.upload-tags-edit-input {
    width: 90px;
}

.render-format-div {
    width: 250px;
    height: 250px;
    margin: auto;
}
.render-view-image {
    height: inherit;
    width: inherit;
}

.upload-tags-column {
    width: 45px;
}

.tabs-no-results-div {
    text-align: center;
}

.tabs-no-results {
    height: 262px;
    width: 300px;
}

.upload-fetching-tags {
    color: rgb(0 92 117);
}
.upload-fetching-tags:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        font-size: 30px;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: rgb(0 92 117);
        font-size: 30px;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: 0.25em 0 0 rgb(0 92 117), 0.5em 0 0 rgba(0, 0, 0, 0);
        font-size: 30px;
    }
    80%,
    100% {
        text-shadow: 0.25em 0 0 rgb(0 92 117), 0.5em 0 0 rgb(0 92 117);
        font-size: 30px;
    }
}
.upload-tags-div .tag button.close {
    font-size: 11px;
}

.upload-edit-div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 3px;
}

.upload-edit-div button {
    width: 90px;
}

.upload-image-div {
    height: 50px;
    width: 50px;
}

.render-format-spinner-div{
    text-align: center;
    padding: 20%;
}

.upload-approve-div {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}
.upload-approve-div button {
    width: 90px;
}
.upload-collection-actions-column{
    width: 25px;
}

.upload-list-button-div{
    position: absolute;
    right: 0px;
    top: -34px;
}

.upload-list-button-div button{
    width:179px;
}

.approve-select-column-div{
    text-align: center;
}

.approve-select-column-div div {
   margin: 0px !important;
}

.approve-select-column-div div input {
   position: static !important;
}
