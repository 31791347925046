

.tabs {
    position: absolute;
    display: flex;
    z-index: 1;
    align-self: center;
    transition: left 0.5s, right 0.5s;
    user-select: none;
    border-radius: 4px;

    height: calc(100% - 120px);
    left: 0;
    margin-top: 10px;
    top: 97px;
}

.tabs--hidden {
    display: flex;
    align-items: flex-start;
    height: 95%;
    left: -490px;
}

.tabs__items {
    overflow: hidden;
    pointer-events: auto;
    user-select: none;
    margin-top: 0;
}

.tabs__content {
    border-radius: 4px;
    width: 300px;
    height: 100%;
    background: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    pointer-events: auto;
}

.content-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.content-panel.content-panel--hidden {
    display: none;
}

.content-panel__title {
    cursor: default;
    user-select: none;
    padding: 10px 20px;
    height: 34px;
    background-color: #e5f6fd;
    color: #697582;
    font-size: 14px;
}
.content-panel--shapes {
  position: relative;
}
.content-panel--shapes-container {
  overflow-y: hidden;
  height: 100%;
}
.content-panel--shapes .smart-shapes {
    height: 95%;
    padding: 5px;
}
.placeholder-label {
    margin-bottom: 0;
}

.content-panel__add-button {
    flex: none;
    z-index: 1;
    cursor: default;
    user-select: none;
    margin: 10px 0 10px;
}

.tabs-hide-button {
    position: absolute;
    z-index: 2;
    outline: none;
    border: none;
    padding: 15px;
    background-color: transparent;
    cursor: pointer;
    bottom: 0;
    left: 0;
    transform: rotate(90deg);
}

.tabs-hide-button svg {
    width: 25px;
    height: 25px;
}

.tabs-hide-button--active {
    transform: rotate(270deg);
}

.tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 30px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    transition: fill 300ms, color 300ms;

}

.tab-item use {
    fill: #697582;
    color: #697582;
    transition: fill 300ms, color 300ms, stroke 300ms;
}
.tab-item:hover,
.tab-item.tab-item--active
.tab-item__icon-wrapper {
        background: #fff;
    }

.tab-item:hover,
.tab-item.tab-item--active
.tab-item__title {
        color: #00b5e2;
    }

.tab-item:hover,
.tab-item.tab-item--active
use {
        fill: #00b5e2;
        color: #00b5e2;
    }

.dcl-widget-edit-text {
    margin-bottom: 10px;
    overflow: auto;
}

.tab-item__title {
    margin-top: 5px;
    color: #697582;
    font-size: 12px;
    transition: color 300ms;
}

.tab-item__icon {
    width: 48px;
    height: 48px;
}

.dcl-widget-upload-list,
.dcl-widget-edit-text,
.dcl-widget-add-shape {
    flex: 1;
    z-index: 0;
}

.dcl-widget-upload-list {
    margin-bottom: 10px;
    overflow: auto;
}

.dcl-widget-add-shape {
    margin-top: 20px;
}

.dcl-widget-add-text,
.dcl-widget-upload-button,
.dcl-widget-add-placeholder-image {
    padding: 0 30px;
}

.tab-item__info-icon {
    margin-top: 1px;

}

.tab-item__info-icon  svg {
    padding: 2px;
}
.tab-item__info-icon:hover {
    fill: #00b5e2;
}

.tab-item__info-icon-wrapper {
    background-color: #e1e6ea;
    border: 3px solid #697582;
}

.tab-item__info-icon-wrapper--active,
.tab-item__info-icon-wrapper:hover {
    background-color: #fff;
    border: 3px solid #00b5e2;
}

.tab-item-information {
    padding-left: 1px;
}
