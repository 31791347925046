@import url(//fonts.googleapis.com/css2?family=Open+Sans:wght@400;600);

@font-face {
    font-family: Gotham-Rounded;
    src: url('./fonts/Gotham-Rounded-Light.ttf');
}

@font-face {
    font-family: Gotham-Rounded-Book;
    src: url('./fonts/Gotham-Rounded-Book.ttf');
}

@font-face {
    font-family: Gotham-Rounded-Medium;
    src: url('./fonts/Gotham-Rounded-Medium.ttf');
}
